<template>
  <div class="profile-contract-id">
    <div class="container fluid">
      <div class="row aic">
        <div class="col-auto">
          <ul class="breadcumb">
            <li>
              <router-link :to="{ name: 'profile.contracts' }">
                {{ $localize("profile")["contract"]["contracts"] }}
              </router-link>
            </li>

            <img src="@/assets/profile_assets/chevron-right.svg" alt="" />

            <li>
              <a href="javascript:" class="active">
                {{ $localize("profile")["contract"]["number"] }}
                №{{ $route.params.id }}
              </a>
            </li>
          </ul>
        </div>

        <div class="col-auto">
          <router-link :to="{ name: 'profile.contracts' }" class="btn-back">
            {{ $localize("button")["back"] }}
          </router-link>
        </div>
      </div>

      <div class="page-title">
        <div>
          <h1>
            {{ $localize("profile")["contract"]["number"] }}
            №{{ $route.params.id }}
          </h1>
          <div class="mb-22">
            <status-card class="status" :type="contract.status"></status-card>
          </div>
        </div>

        <a
          v-if="contract.status === 1"
          target="_blank"
          :href="`${hostName}${contract.doc_pdf}`"
          class="download-contract-icon"
        >
          <img src="@/assets/icons/download.svg" alt="" />

          <span class="download-contract-label">
            {{ $localize("button")["downloadContract"] }}
          </span>
        </a>
        <a
          v-if="contract.status === 0"
          target="_blank"
          href="https://paymart.uz/images/files/oferta_uz.pdf"
          class="download-contract-icon"
        >
          <img src="@/assets/icons/download.svg" alt="" />

          <span class="download-contract-label">{{
            $localize("button")["offerPreview"]
          }}</span>
        </a>
      </div>

      <loading v-if="!contract.products" size="xl" />

      <div v-else class="contract-container">
        <p class="products-title">{{ $localize("product")["plural"] }}</p>

        <products-table
          v-if="contract.products"
          class="table-products"
          :products="contract.products"
          :remainder="Number(contract.remainder)"
        />

        <div
          :class="{
            row: true,
            'mb-40':
              contract.status === 1 || contract.status === 3 || contract.status === 4,
          }"
          class="d-flex align-items-center"
        >
          <!--Срок рассрочки-->
          <div class="col-md-4">
            <div class="info-card">
              <div class="icon">
                <img src="@/assets/profile_assets/time-info-card.svg" alt="" />
              </div>

              <div class="info_title">
                <h3>{{ $localize("profile")["contract"]["installmentTerm"] }}:</h3>

                <p v-if="notActive">
                  {{ $t("month", { number: contract.period }) }}
                </p>
              </div>
            </div>
          </div>

          <!--К оплате на этот месяц-->
          <div
            v-if="contract.status === 1 || contract.status === 3 || contract.status === 4"
            class="col-md-4"
          >
            <div class="info-card">
              <div class="icon">
                <img src="@/assets/profile_assets/cash-info-card.svg" alt="" />
              </div>

              <div class="info_title">
                <h3>{{ $localize("profile")["contract"]["thisMonth"] }}</h3>

                <p>
                  {{ $t("currency", { price: $numberFormat(contract.current_pay) }) }}
                </p>
              </div>
            </div>
          </div>

          <!--Для досрочного погашения-->
          <div
            v-if="contract.status === 1 || contract.status === 3 || contract.status === 4"
            class="col-md-4"
          >
            <div class="info-card">
              <div class="icon">
                <img src="@/assets/profile_assets/cash-info-card.svg" alt="" />
              </div>

              <div class="info_title">
                <h3>{{ $localize("profile")["contract"]["repayment"] }}</h3>

                <p>
                  {{ $t("currency", { price: $numberFormat(contract.remainder) }) }}
                </p>
              </div>
            </div>
          </div>

          <!--Ежемесячный платеж-->
          <div class="col-md-4">
            <div
              :class="{
                'info-card': true,
                monthlyPayment: true,
                'mt-35':
                  contract.status === 1 || contract.status === 3 || contract.status === 4,
              }"
              @click="
                pushRoute({
                  name: 'profile.graphic',
                  params: { id: $route.params.id },
                })
              "
            >
              <div class="icon">
                <img src="@/assets/profile_assets/cash-info-card.svg" alt="" />
              </div>

              <div class="info_title">
                <h3>{{ $localize("profile")["contract"]["monthlyPayment"] }}</h3>

                <p>
                  {{ $t("currency", { price: $numberFormat(contract.monthly_payment) }) }}
                </p>
              </div>

              <router-link
                class="chevron-link"
                :to="{
                  name: 'profile.graphic',
                  params: { id: $route.params.id },
                }"
              >
                <img src="@/assets/profile_assets/chevron-right.svg" alt="" />
              </router-link>
            </div>
          </div>

          <!--qr_code-->
          <div class="col-md-6" v-if="contract.status !== 9">
            <div class="qr-code__style mt-20" v-show="this.url">
              <img v-if="this.url" :src="this.url" alt="qr-code" width="80" height="80" />
              <div>
                <strong
                  style="width: 40%"
                  v-html="$localize('contractPage')['qrCodeSec']"
                ></strong>
                <p
                  style="font-family: 12px; margin: 0"
                  v-html="$localize('contractPage')['cashBack']"
                ></p>
              </div>
            </div>
          </div>
          <div class="col-md-4" v-if="contract.status === 9">
            <div class="qr-code__style" v-show="this.url">
              <img v-if="this.url" :src="this.url" alt="qr-code" width="80" height="80" />
              <div>
                <strong
                  style="width: 40%"
                  v-html="$localize('contractPage')['qrCodeSec']"
                ></strong>
                <p
                  style="font-family: 12px; margin: 0"
                  v-html="$localize('contractPage')['cashBack']"
                ></p>
              </div>
            </div>
          </div>
        </div>

        <p v-if="contract.status !== 1 && activation" class="label-sms-code-input">
          {{ $localize("profile")["contract"]["labelSMSCode"] }}
        </p>

        <div v-if="activation" class="row">
          <div v-if="contract.status !== 1" class="col-md-4 d-flex align-items-center">
            <input
              id="askCodeActivation"
              v-model="code"
              type="tel"
              class="form-control"
              :placeholder="$localize('sms-code')['title']"
            />
          </div>

          <div v-if="contract.status !== 1" class="col-md-4 d-flex align-items-center">
            <main-button :is-loading="loading" @click="checkActivationCode">
              {{ $localize("button")["done"] }}
            </main-button>
          </div>
        </div>

        <div class="row">
          <div
            v-if="contract.status === 1 || contract.status === 3 || contract.status === 4"
            class="col-md-4"
          >
            <main-button :is-loading="loading" @click="active = true">
              {{ $localize("button")["pay"] }}
            </main-button>
          </div>

          <div
            v-if="this.contract.status === 0"
            :class="{ 'col-md-10': true, 'mt-35 flex': !activation }"
          >
            <div class="qr-codes">
              <div>
                <img
                  src="../../assets/icons/ContractPageQrCode.svg"
                  alt="qr-code"
                  width="80"
                  height="80"
                />
                <p v-html="$localize('contractPage')['qrCode']"></p>
              </div>
            </div>
            <div class="mobile-qr">
              <p v-html="$localize('contractPage')['qrCodeMobile']"></p>
              <main-button v-if="contract.status === 0" :is-loading="loading">
                <a href="https://onelink.to/xafrst" style="color: #fff" target="_blank">
                  {{ $localize("contractPage")["downloadBtn"] }}
                </a>
              </main-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <my-overlay :active="active" @closeOverlay="closeOverlay" />

    <transition>
      <contract-pay v-if="active" :contracts="contract" @closeOverlay="closeOverlay" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";

import ContractPay from "@/components/profile/ContractPay.vue";
import StatusCard from "@/components/cards/StatusCard";
import ProductsTable from "@/components/tables/ProductsTable";
import MainButton from "@/components/buttons/MainButton";
// mixins
import hostNameMixin from "@/mixins/hostName.mixin";
import MyOverlay from "@/components/UI/MyOverlay.vue";

export default {
  name: "ContractsPage",
  mixins: [hostNameMixin],
  data: () => ({
    active: false,
    activation: false,
    contract: {},
    notActive: true,
    load: false,
    loading: false,
    phoneNumber: localStorage.getItem("number"),
    api_token: Cookies.get("token"),
    code: null,
    url: "",
    urlLast: "",
    urlSecondLast: "",
  }),
  components: {
    ContractPay,
    StatusCard,
    ProductsTable,
    MainButton,
    MyOverlay,
  },
  computed: {
    ...mapGetters("buyers", ["cardUser", "userInfo"]),
    balance() {
      return this.$store.getters["buyers/balance"];
    },
  },
  watch: {
    active(val) {
      if (val) {
        document.querySelector(".b24-widget-button-wrapper").style.display = "none";
      } else {
        document.querySelector(".b24-widget-button-wrapper").style.display = "block";
      }
    },
  },
  methods: {
    closeOverlay() {
      this.active = false;
    },
    pushRoute(route) {
      this.$router.push(route);
    },
    async sendActivation() {
      this.loading = true;

      try {
        const { data: response } = await this.$axios.post("buyers/send-code-sms", {
          contract_id: this.$route.params.id,
          phone: this.phoneNumber,
        });
        if (response.status === "success") {
          this.loading = false;
          this.activation = true;
        } else {
          this.loading = false;

          this.$toastError(this.$localize("error")[response.response.message]);
        }
      } catch (e) {
        this.loading = false;

        await this.$toastError(e.message);
      }
    },
    async checkActivationCode() {
      this.loading = true;

      try {
        const { data } = await this.$axios.post(`buyers/check-code-sms`, {
          contract_id: this.$route.params.id,
          phone: this.userInfo.phone,
          code: this.code,
        });

        if (data.status === "success") {
          this.loading = false;

          await this.$toastSuccess(data.response.message);
          await this.$router.push({ name: "success" });
        } else {
          this.loading = false;

          this.$toastError(data.response.message);
        }
      } catch (e) {
        this.loading = false;

        this.$toastError(e.message);
      }
    },
  },
  async created() {
    // this.load = true;
    try {
      const { data: response } = await this.$axios.post(`buyer/contract`, {
        contract_id: this.$route.params.id,
      });

      if (response.status === "success") {
        this.contract = response.contracts;
        if (response.contracts.url) {
          this.urlLast = "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=";
          this.urlSecondLast = "&choe=UTF-8";
          this.url =
            this.urlLast +
            encodeURIComponent(response.contracts.url) +
            this.urlSecondLast;
        }
      } else {
        await this.$router.push({ name: "empty" });
      }
    } catch (e) {
      this.$toastError(e.message);
    }
  },
};
</script>

<style lang="scss" scoped>
.form_input {
  margin-bottom: 32px;
}

.flex {
  div {
    display: flex;
    align-items: center;
  }

  p {
    margin-left: 23px;
    font-size: 16px;
    line-height: 24px;
    color: #1e1e1e;
  }

  .mobile-qr {
    display: none;
  }

  @media (max-width: $mobile) {
    div {
      display: none;
    }

    .mobile-qr {
      p {
        margin-left: 0;
        text-align: center;
        padding: 10px 0;
      }

      display: block;
    }
  }
}

.mt-35 {
  margin-top: 35px;
}

.form-control {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: $black;
  box-sizing: border-box;
  background: $grey;
  border-radius: 8px;
  border: none;
  transition: 0.4s;

  @media (max-width: $mobile) {
    padding: 10px 16px;
    font-size: 14px;
  }

  &::placeholder {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: rgba(30, 30, 30, 0.3);
  }

  &:focus {
    border: 1px solid #6610f5;
    outline: none;
    color: rgb(30, 30, 30);
  }
}

.status {
  /*margin-bottom: 24px;*/
  background-color: transparent;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
}

.profile-contract-id {
  .table-products {
    margin-bottom: 32px;
  }

  .page-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .status-not-activated {
      color: $fail;
      margin-bottom: 24px;
    }

    @media (max-width: $mobile) {
      justify-content: space-between;
    }

    .download-contract-icon {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
      }

      .download-contract-label {
        color: $main;

        @media (max-width: $mobile) {
          display: none;
        }
      }

      @media (max-width: $mobile) {
        margin-right: 0;
      }
    }
  }

  .contract-container {
    padding: 24px;
    background-color: $white;
    border-radius: 8px;

    @media (max-width: $mobile) {
      margin-bottom: 100px;
      padding: 12px 8px;
    }

    .products-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 16px;

      @media (max-width: $mobile) {
        font-size: 16px;
      }
    }

    .label-sms-code-input {
      margin-bottom: 8px;
      margin-top: 28px;
    }
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .aic {
    align-items: center;
    justify-content: space-between;
  }

  button.p-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 18px 24px;

    font-weight: 600;
    font-size: 18px;
    line-height: 111.63%;

    border-radius: 14px;
    border: none;

    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    color: $white;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      background: $main-light;
    }
  }

  .btn-back {
    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 8px;
    width: auto;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border: none;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      background: $main-light;
    }
  }

  padding: 48px 44px;
  min-height: calc(100vh - 96px);
  background: $grey;
  width: 100%;

  .breadcumb {
    display: flex;
    align-items: center;

    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: $black;
      opacity: 0.3;

      &.active {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: $main;
        opacity: 1;
      }
    }
  }

  .chevron-link {
    margin-left: auto;
  }

  h1 {
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: $black;
    margin-bottom: 8px;
    margin-top: 16px;
  }

  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $black;
  }

  .info-card {
    width: 100%;
    padding: 12px;
    background: $white;
    border-radius: 8px;
    display: flex;
    align-items: center;

    &.monthlyPayment {
      cursor: pointer;
      background-color: $grey;

      &.mt-35 {
        margin-top: 30px;
      }
    }

    .icon {
      padding: 8px;
      background: rgba(102, 16, 245, 0.1);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
    }

    .info_title {
      h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: $black;
        margin-bottom: 4px;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: $main;
      }
    }
  }
}

.qr-codes {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 575px) {
  .profile-contract-id {
    padding: 27px 0px;
    min-height: calc(100vh - 48px);
    width: auto;
    margin-left: -16px;
    margin-right: -16px;

    .btn-back {
      display: none;
    }

    .mb-40 {
      margin-bottom: 8px;
    }

    h1 {
      margin-bottom: 16px;
      margin-top: 8px;
      font-size: 20px;
      line-height: 25px;
    }

    h2 {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.38px;
      margin-top: calc(24px - 8px);
    }

    button.p-button {
      width: 100%;
      font-size: 15px;
      margin-top: 8px;
    }

    .info-card {
      padding: 10px 12px;

      .icon {
        width: 36px;
        height: 36px;

        img {
          width: 20px;
        }
      }

      .info_title {
        h3 {
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.01em;
        }

        p {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.01em;
          font-weight: 400;
        }
      }
    }
  }
}

.qr-code__style {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*width: 20%;*/
  /*height: 20%;*/
}
</style>
