<template>
  <div class="table">
    <h3>
      {{ $localize("profile")["contract"]["paymentPeriod"] }}
    </h3>
    <info-card
      class="table__info"
      :title="$localize('profile')['contract']['payFor']"
      sub-title="null"
    >
      <template #subTitle>
        <span class="table__info-price">{{ $numberFormat(total) }}</span>
      </template>

      <template #action>
        <span class="table__info-check" @click="checkAll">
          <label class="cursor-pointer" for="check-all">
            {{
              checkedPeriods.length
                ? $localize("profile")["contract"]["clearAll"]
                : $localize("profile")["contract"]["selectAll"]
            }}
          </label>
          <input type="checkbox" hidden />
        </span>
      </template>
    </info-card>
    <div class="table__body">
      <div
        class="table__body-item"
        v-for="(period, index) in schedule_list"
        :key="period.id"
      >
        <div class="table__body-left">
          <h4>{{ $t("month", { number: period.month, hyphen: "-" }) }}</h4>

          <p>{{ period.payment_date }}</p>
        </div>

        <div class="table__body-right">
          <div class="price">
            {{ $t("currency", { price: $numberFormat(period.balance) }) }}
          </div>

          <my-checkbox class="checkbox" :id="index" @change="checkPeriodHandler(index)" />
        </div>
      </div>
    </div>
    <main-button
      @click="applyPeriods"
      style="margin-top: 15px"
      :disabled="checkedPeriods.length === 0"
    >
      {{ $localize("button")["continue"] }}
    </main-button>
  </div>
</template>

<script>
import MainButton from "@/components/buttons/MainButton";
import MyCheckbox from "@/components/UI/MyCheckbox";
import InfoCard from "@/components/cards/InfoCard";
export default {
  components: {
    MainButton,
    MyCheckbox,
    InfoCard,
  },
  props: {
    schedule_list: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    total: 0,
    checkedPeriods: [],
    checkAllPeriods: false,
  }),
  methods: {
    checkAll() {
      this.checkAllPeriods = !this.checkedPeriods.length && !this.checkAllPeriods;
      this.checkAllPeriods
        ? this.checkPeriodHandler(this.schedule_list.length - 1)
        : this.checkPeriodHandler();
    },
    checkPeriodHandler(index) {
      const checkboxes = document.querySelectorAll(".checkbox input");

      checkboxes.forEach((item) => (item.checked = false));
      this.checkedPeriods = [];

      for (let i = 0; i <= index; i++) {
        this.checkedPeriods.push(this.schedule_list[i]);
        checkboxes[i].checked = true;
      }
      this.total = this.checkedPeriods.reduce(
        (total, period) => total + +period.balance,
        0
      );
    },
    applyPeriods() {
      const checkedPeriodsIds = this.checkedPeriods.map((item) => item.id);
      this.$emit("applyByPeriodPayment", checkedPeriodsIds, this.total);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    &-check {
      color: $main;
    }
  }

  &__body {
    height: 50vh;
    overflow-y: scroll;
    padding-right: 8px;

    @media (max-width: $notebook) {
      height: 40vh;
    }

    &-item {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.04);

      h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $black;
        margin-bottom: 8px;
      }

      p {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: $black;
      }

      @media (max-width: $mobile) {
        padding-top: 10px;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .price {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: $main;
        margin-right: 16px;
      }
    }
  }
}
</style>
