<template>
  <div class="card_balance">
    <!-- checkBy payConfirm and step -->
    <div class="top">
      <div class="icon">
        <img src="@/assets/profile_assets/credit-card-hand.svg" alt="card-hand" />
      </div>
      <div class="close" @click="$emit('closeOverlay')">
        <img src="@/assets/profile_assets/closeSidebar.svg" alt="closeSidebar" />
      </div>
    </div>

    <!-- типы оплаты -->
    <template v-if="step === 1">
      <h3 class="mb-10">{{ $localize("profile")["checkPaymentType"] }}</h3>
      <ul>
        <li
          class="info-card mb-8 cursor-pointer"
          v-for="(payment, index) in payments"
          :key="index"
          @click="selectPayment(payment.type)"
        >
          <div class="icon">
            <img :src="payment.icon" :alt="payment.text" />
            <span>{{ payment.text }} </span>
          </div>
          <svg
            width="21"
            height="21"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.35355 5.64645C9.15829 5.45118 8.84171 5.45118 8.64645 5.64645C8.45118 5.84171 8.45118 6.15829 8.64645 6.35355L9.35355 5.64645ZM14.2929 11.2929L13.9393 11.6464V11.6464L14.2929 11.2929ZM14.2929 12.7071L13.9393 12.3536V12.3536L14.2929 12.7071ZM8.64645 17.6464C8.45118 17.8417 8.45118 18.1583 8.64645 18.3536C8.84171 18.5488 9.15829 18.5488 9.35355 18.3536L8.64645 17.6464ZM8.64645 6.35355L13.9393 11.6464L14.6464 10.9393L9.35355 5.64645L8.64645 6.35355ZM13.9393 12.3536L8.64645 17.6464L9.35355 18.3536L14.6464 13.0607L13.9393 12.3536ZM13.9393 11.6464C14.1346 11.8417 14.1346 12.1583 13.9393 12.3536L14.6464 13.0607C15.2322 12.4749 15.2322 11.5251 14.6464 10.9393L13.9393 11.6464Z"
              fill="#000000"
            />
          </svg>
        </li>
      </ul>
    </template>

    <!-- оплата по выранным месяцам -->
    <template v-else-if="step === 2">
      <by-periods
        v-if="paymentType === 'several-month'"
        :schedule_list="schedule_list"
        @applyByPeriodPayment="applyByPeriodPayment"
      />
    </template>

    <template v-else-if="step === 3">
      <payment-confirm
        @confirmPayment="confirmPaymentHandler"
        :paymentType="paymentType"
        :checkedPeriods="checkedPeriodsIds.length"
        :checkedPeriodsAmount="total"
        :currentPay="contracts.current_pay"
        :loading="loading"
        :month="month"
      />
    </template>

    <template v-else-if="step === 4">
      <sms-confirm
        :phone="cardOwnerPhone"
        :loading="loading"
        @confirmSmsCode="confirmSmsCodeHandler"
      />
    </template>

    <!-- final step -->
    <template v-else-if="step === 5">
      <div class="success">
        <img src="@/assets/profile_assets/success.svg" alt="success" />

        <h3>{{ $localize("profile")["balanceUp"]["successPaid"] }}</h3>
      </div>
      <!--  ссылка на contract marketplace  -->
      <main-button
        v-if="this.isMarketPlace"
        class="p-button"
        onclick="window.location.href = 'http://market.paymart.uz/index.php?dispatch=installment_product.profile-contracts'"
      >
        {{ $localize("profile")["contract"]["goBack"] }}
      </main-button>

      <main-button
        v-if="!this.isMarketPlace"
        class="p-button"
        @click="$emit('closeOverlay')"
        :class="{ 'btn-loading': loading }"
      >
        {{ $localize("button")["close"] }}
      </main-button>
    </template>

    <div v-if="this.step === 1" class="footer">
      <div class="monthly">
        <p class="m-0">{{ $localize("profile")["contract"]["thisMonth"] }}</p>
        <span>{{ $t("currency", { price: $numberFormat(contracts.current_pay) }) }}</span>
      </div>
      <div class="all">
        <p class="m-0">{{ $localize("profile")["contract"]["allPeriod"] }}</p>
        <span>{{ $t("currency", { price: $numberFormat(contracts.remainder) }) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import cashIcon from "@/assets/profile_assets/cash-info-card.svg";
import coinsIcon from "@/assets/icons/coins.svg";
import clockIcon from "@/assets/icons/clock.svg";

import BuyerService from "@/services/buyer.service";
import MainButton from "@/components/buttons/MainButton";
import ByPeriods from "@/components/profile/ContractPay/PaymentTypes/ByPeriods";
import SmsConfirm from "@/components/profile/ContractPay/SmsConfirm";
import PaymentConfirm from "@/components/profile/ContractPay/PaymentConfirm";

import { createNamespacedHelpers } from "vuex";
import axios from "axios";

const { mapGetters: mapBuyerGetters } = createNamespacedHelpers("buyers");

const CONTRACT_PAY_API = `${process.env.VUE_APP_API_DOMAIN}/api/v2/buyer/contract/pay`

export default {
  components: {
    PaymentConfirm,
    SmsConfirm,
    MainButton,
    ByPeriods,
  },
  props: {
    contracts: { type: Object },
    isMarketPlace: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    paymentTypes: ["month", "free-pay", "several-month"],
    amount: "",
    selectedCardId: 0,
    month: 0,
    loading: false,
    paymentType: null,
    schedule_list: [],
    step: 1,
    checkedPeriodsIds: [],
    total: 0,
    cardOwnerPhone: "",
  }),
  computed: {
    payments() {
      return [
        {
          icon: cashIcon,
          text: this.$localize("profile")["contract"]["paymentType"]["oneMonth"],
          type: "month",
        },
        {
          icon: coinsIcon,
          text: this.$localize("profile")["contract"]["paymentType"]["random"],
          type: "free-pay",
        },
        {
          icon: clockIcon,
          text: this.$localize("profile")["contract"]["paymentPeriod"],
          type: "several-month",
        },
      ];
    },
    ...mapBuyerGetters(["userInfo"]),
  },
  methods: {
    applyByPeriodPayment(checkedPeriodsIds, total) {
      this.step++;
      this.checkedPeriodsIds = checkedPeriodsIds;
      this.total = total;
    },
    selectPayment(type) {
      this.paymentType = type;

      if (type !== "several-month") {
        this.step += 2;
        return;
      }

      this.step++;
    },
    getUserData(smsCode = null) {
      let userData = {
        payment_type: this.paymentType,
        user_id: this.userInfo.id,
        contract_id: this.contracts.contract_id,
        type: this.selectedCardId == 0 ? "account" : "card",
      };

      if (this.selectedCardId != 0) {
        userData["card_id"] = this.selectedCardId;
      }

      if (this.paymentType === "several-month") {
        userData["schedule_ids"] = this.checkedPeriodsIds;
      } else if (this.paymentType === "free-pay") {
        userData["amount"] = this.amount?.replace(/[^0-9]/g, "");
      }

      if (smsCode) {
        userData["otp"] = smsCode;
      }
      return userData;
    },
    getSmsCodeHandler() {
      this.loading = true;
      let userData = this.getUserData();

      axios
        .post(CONTRACT_PAY_API, userData)
        .then(async ({ data }) => {
          this.loading = false;
          if (data.status == "success") {
            if (this.selectedCardId == 0) {
              this.step = 5;
              BuyerService.balance(this.$route.query?.api_token);
              return;
            }
            await this.getBuyerNumber();
            this.step++;
          } else {
            data.response.errors.forEach((err) => this.$toastError(err));
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$toastError(error);
        });
    },
    confirmSmsCodeHandler(smsCode) {
      this.loading = true;
      let userData = this.getUserData(smsCode);

      axios
        .post(CONTRACT_PAY_API, userData)
        .then(({ data }) => {
          this.loading = false;

          if (data.status == "success") {
            this.step++;
            BuyerService.balance(this.$route.query?.api_token);
          } else {
            data.response.errors.forEach((err) => this.$toastError(err));
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$toastError(error);
        });
    },
    confirmPaymentHandler(selectedCardId, value = null) {
      this.amount = value;
      this.selectedCardId = selectedCardId;
      this.getSmsCodeHandler();
    },
    async getBuyerNumber() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post("card/get-card-info", {
          card_id: this.selectedCardId,
        });
        if (data.status === "success") {
          this.cardOwnerPhone = data.data.phone;
          return;
        }
      } catch (err) {
        console.log("request Error:", err);
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    const token = this.$route.query.api_token;
    this.schedule_list = await BuyerService.scheduleList(this.$route.params.id, token);
    this.month = this.$moment(this.schedule_list[0].payment_date, "DD.MM.YYYY").month();
  },
};
</script>

<style lang="scss" scoped>
.card_balance {
  padding: 20px 32px;
  max-width: 400px;
  background: $white;
  width: 400px;
  min-height: calc(100vh - 96px);
  position: fixed;
  top: 96px;
  right: 0;
  z-index: 1000;
  transform: translateX(0);
  transition: 0.4s;
  overflow-x: scroll;

  &.v-enter-active,
  &.v-leave-active {
    transition: transform 500ms ease;
  }

  &.v-enter,
  &.v-leave-to {
    transform: translate(500px);
  }

  .card-body {
    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 8px;
    }
  }

  .info-card {
    width: 100%;
    padding: 16px 12px;
    background: $grey;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      display: flex;
      align-items: center;

      img {
        margin-right: 12px;
      }

      span {
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
      }
    }

    .price {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      color: $main;
      display: flex;
      align-items: center;

      svg {
        fill: $black;
        opacity: 0.3;
      }
    }
  }

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .icon {
      padding: 6px;
      background: rgba(102, 16, 245, 0.1);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      margin-bottom: 24px;
    }
  }

  .close {
    cursor: pointer;
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    font-size: 13px;
    width: 100%;
    line-height: 16px;
    border-top: 2px solid $grey;

    .monthly {
      width: 50%;
      border-right: 2px solid $grey;
      padding: 16px;

      @media (max-width: $mobile) {
        padding-top: 16px;
      }

      span {
        color: $main;
      }
    }

    .all {
      width: 50%;
      padding: 16px;

      @media (max-width: $mobile) {
        padding-top: 16px;
      }

      span {
        color: $main;
      }
    }
  }
}

.success {
  text-align: center;
  margin-top: 50px;

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $black;
    margin-top: 28px;
    margin-bottom: 40px;
  }
}
</style>
