<template>
  <div class="info-card">
    <div :class="{ 'd-flex': true, 'justify-content-between': true }">
      <div
        v-if="hasIcon"
        class="icon-container d-flex align-items-center justify-content-center mr-12"
      >
        <img :src="icon" width="24" height="24" />
      </div>

      <div v-else :class="`rectangle mr-3 ${color}`"></div>

      <div>
        <p class="mb-0 font-weight-700">{{ title }}</p>
        <span>
        <fragment v-if="subTitle !== 'null'">{{ subTitle }}</fragment>
        <slot v-else name="subTitle"></slot>
      </span>
      </div>
    </div>

    <slot name="action"></slot>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import car from '../../assets/icons/user.svg';

export default {
  name: 'InfoCard',
  props: {
    hasIcon: VueTypes.bool.def(true),
    color: VueTypes.string.def('primary'),
    icon: VueTypes.string.def(car),
    title: VueTypes.string.def('Новый'),
    subTitle: VueTypes.string.def('3221'),
  },
};
</script>

<style lang="scss" scoped>
  $primary: rgba(102, 16, 245, 0.1);

  .info-card {
    width: 100%;
    display: flex;
    align-items: center;

    .icon-container {
      background-color: $primary;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
    }

    .rectangle {
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }

    p {
      line-height: 19px;
    }

    span {
      line-height: 19px;
    }
  }
</style>
