<template>
  <div class="checkbox">
    <input
      type="checkbox"
      :id="id"
      @change="$emit('change')"
    />
    <label :for="id">
      <svg
        width="11"
        height="8"
        viewBox="0 0 11 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.29999 1.20001L3.79999 6.70001L1.29999 4.20001"
          stroke="#fff"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </label>
  </div>
</template>

<script>
export default {
  name: "TableCheckbox",
  props: {
    id: {
      type: Number,
    },
  },
};
</script>

<style lang="scss">
.checkbox {
  transition: all 300ms linear;
  input {
    display: none;
  }
  input:checked + label {
    background-color: $main;
    border-color: $main;
  }
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    width: 25px;
    height: 25px;
    border-radius: 8px;
    svg {
      height: 15px;
      width: 15px;
    }
  }
  &:hover {
    label {
      background-color: #ccc;
    }
  }
}

@media (max-width: 575px) {
}
</style>
