<template>
  <div>
    <h3>{{ $localize("sms-code")["title"] }}</h3>
    <p style="margin-bottom: 15px">
      {{ $localize("sms-code")["text"] }} +{{ phone }}
    </p>
    <sms-code-input
      v-model="smsCode"
      class="fields-container"
      :length="4"
      input-type="tel"
      pattern="[^0-9]+"
      :ignorePattern="false"
      fieldClass="custom-field-class"
      :size="32"
      :disabled="false"
      type="tel"
    />

    <main-button
      :disabled="smsCode.length != 4"
      :isLoading="loading"
      @click="$emit('confirmSmsCode', smsCode)"
    >
      {{ $localize("button")["continue"] }}
    </main-button>
  </div>
</template>

<script>
import MainButton from "@/components/buttons/MainButton";
export default {
  props: {
    phone: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MainButton,
  },
  data: () => {
    return {
      smsCode: "",
    };
  },
};
</script>

<style lang="scss" scoped></style>
