<template>
  <ValidationObserver v-slot="{ handleSubmit, invalid }">
    <!-- оплата -->
    <h2 class="mb-10">
      {{ $localize("profile")["history"]["paymentType"]["user_auto"] }}
    </h2>

    <p class="mb-10">
      {{ $localize("profile")["contract"]["paymentCard"] }}
    </p>

    <!-- карточки  -->
    <swiper class="swiper" :options="swiperOption" @slideChange="onSlideChange">
      <template v-if="!cardUser.length">
        <Loading size="lg" />
      </template>
      <swiper-slide v-else v-for="(card, i) in cardUser" :key="i">
        <div
          class="card"
          :style="`background:url(${card.style}) no-repeat center center / cover;`"
          :class="card.theme"
        >
          <div class="card_top">
            <div class="card_left">
              <h3 v-if="card.exp === '--/--'">
                {{ $localize("balancePopup")["personalBalance"] }}
              </h3>
              <h3 v-else>{{ $localize("balancePopup")["paymartCart"] }}</h3>
              <p>
                {{ $t("currency", { price: $numberFormat(card.balance || 0) }) }}
              </p>
            </div>

            <div class="card_right">
              <div class="frame" :style="`background-image:url(${card.logo});`"></div>
            </div>
          </div>

          <div class="card_bottom">
            <div class="card_left">{{ card.pan }}</div>

            <div class="card_right">{{ card.exp }}</div>
          </div>
        </div>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <!-- оплата за месяц -->
    <div class="info" v-if="paymentType === 'several-month' || paymentType === 'month'">
      <div class="info__card">
        <div class="info__card-icon">
          <img src="@/assets/profile_assets/cash-info-card.svg" alt="cash" />
          <span>
            {{
              checkedPeriods === 0
                ? `${$localize("profile")["contract"]["payment_for_month"]}
                        ${$localize("profile")["contract"]["months"][month]}`
                : `${
                    $localize("profile")["contract"]["payment_for_months"]
                  } ${checkedPeriods}
                        ${$localize("profile")["contract"]["month_end"]}`
            }}
          </span>
        </div>

        <div class="info__card-price">
          {{
            $t("currency", {
              price: $numberFormat(
                checkedPeriods === 0 ? currentPay : checkedPeriodsAmount
              ),
            })
          }}
        </div>
      </div>
    </div>

    <div v-else class="mb-20">
      <label for="amount" class="amount"
        >{{ $localize("product")["sum"] }}: {{ amount }}</label
      >
      <ValidationProvider
        name="amount"
        v-slot="{ errors }"
        rules="required|min_value:1000"
      >
        <input
          :placeholder="$localize('sign-up')['sumPlaceholder']"
          v-model="amount"
          v-mask="priceMask"
          type="tel"
          id="amount"
          class="form-control"
        />
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>
    </div>

    <p v-if="paymentType === 'several-month'" class="mb-10 font-size-13">
      {{
        selectedCardId
          ? $localize("profile")["contract"]["payInfo"]
          : $localize("profile")["contract"]["payAccauntCard"]
      }}
    </p>

    <main-button
      :is-loading="loading"
      :disabled="invalid"
      @click="handleSubmit(confirmPayment)"
    >
      {{ $localize("profile")["contract"]["confirm"] }}
    </main-button>
  </ValidationObserver>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import MainButton from "@/components/buttons/MainButton";
import BuyerService from "@/services/buyer.service";
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import SwiperCore, { Pagination } from "swiper";
import Loading from "@/components/Loading.vue";
SwiperCore.use([Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    MainButton,
    ValidationObserver,
    Loading,
  },
  props: {
    paymentType: {
      type: String,
      default: null,
    },
    checkedPeriods: {
      type: Number,
      default: 0,
    },
    currentPay: {
      type: String,
      default: "0",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    month: {
      type: Number,
      default: 0,
    },
    checkedPeriodsAmount: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 32,
        grabCursor: true,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 576px
          576: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 1,
            spaceBetween: 32,
          },
          1300: {
            slidesPerView: 1,
            spaceBetween: 32,
          },
          1600: {
            slidesPerView: 1,
            spaceBetween: 32,
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      selectedCardId: 0,
      amount: "",
    };
  },
  watch: {
    amount(val) {
      this.amount = val.replace(/^0/gi, "");
    },
  },
  computed: {
    ...mapGetters("buyers", ["cardUser", "userInfo"]),
    priceMask() {
      let mask = "";

      switch (this.amount.length) {
        case 5:
          mask = "# ###";
          break;
        case 6:
          mask = "## ###";
          break;
        case 7:
          mask = "### ###";
          break;
        case 8:
          mask = "# ### ###";
          break;
        case 10:
          mask = "## ### ###";
          break;
        case 11:
          mask = "### ### ###";
          break;

        default:
          mask = "### ### ###";
          break;
      }
      return mask;
    },
  },
  methods: {
    onSlideChange({ activeIndex }) {
      this.selectedCardId = this.cardUser[activeIndex].id;
    },
    confirmPayment() {
      this.$emit("confirmPayment", this.selectedCardId, this.amount);
    },
  },
  async created() {
    await BuyerService.cards();
  },
};
</script>

<style lang="scss" scoped>
.amount {
  font-weight: 700;
  margin: 10px 0;
}

.swiper-container {
  padding-bottom: 25px;
  margin-top: 16px;
}

.swiper-slide {
  .card {
    padding: 16px;
    border-radius: 8px;

    &.whiteThema {
      color: white;
    }

    .card_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 55px;

      .card_left {
        h3 {
          font-size: 16px;
          line-height: 19px;
        }

        p {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
        }
      }

      .card_right {
        .frame {
          width: 72px;
          height: 32px;
          background-repeat: no-repeat;
          background-position: top right;
          background-size: contain;
        }
      }
    }

    .card_bottom {
      display: flex;
      justify-content: space-between;

      .card_left {
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
      }

      .card_right {
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}

.info {
  margin-bottom: 20px;
  width: 100%;
  font-size: 13px;

  .info__card {
    width: 100%;
    padding: 16px 12px;
    background: #f6f6f6;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-price {
      font-weight: 700;
      color: #6610F5;
    }

    &-icon {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 25px;
        height: 25px;
      }

      span {
        display: block;
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: 575px) {
  .card_balance {
    padding: 24px 16px 16px;
    max-width: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    top: auto;
    transform: translateY(2000px);
    overflow: auto;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    min-height: auto;
    padding-bottom: 0;

    &::after {
      content: "";
      display: block;
      position: absolute;
      background: $black;
      opacity: 0.3;
      border-radius: 4px;
      left: 50%;
      top: 10px;
      transform: translateX(-50%);
      width: 56px;
      height: 4px;
    }

    &.active {
      transform: translateY(0px);
    }

    .top {
      display: none;
    }

    .card-body {
      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.38px;
        margin-bottom: 8px;
      }
    }

    ul.toggle {
      margin-bottom: 12px;

      li {
        a {
          padding: 11px 12px;
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
          letter-spacing: -0.154px;
        }
      }
    }

    .form_input {
      margin-bottom: 16px;

      .form-control {
        padding: 10px 16px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;

        &::placeholder {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;
        }
      }
    }

    .info-card {
      padding: 12px 10px;

      .icon {
        img {
          width: 20px;
          height: 20px;
        }
      }

      .info_title {
        h3 {
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.01em;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.01em;
        }
      }
    }
  }

  .swiper-container {
    margin-top: 12px;
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .swiper-slide {
    .card {
      .card_top {
        margin-bottom: 60px;

        .card_left {
          h3 {
            font-size: 14px;
            line-height: 16px;
          }

          p {
            font-weight: bold;
            font-size: 19px;
            line-height: 23px;
          }
        }
      }

      .card_bottom {
        .card_left {
          font-size: 15px;
          line-height: 28px;
        }

        .card_right {
          font-size: 15px;
          line-height: 28px;
        }
      }
    }
  }
}

.error {
  font-size: 14px;
  color: red;
  margin-top: 5px;
}
</style>
