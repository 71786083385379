<template>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">{{ $localize('product')['name'] }}</th>
        <th scope="col">{{ $localize('product')['price'] }}</th>
        <th scope="col">{{ $localize('product')['amount'] }}</th>
        <th scope="col">{{ $localize('product')['sum'] }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="product in products" :key="product.id">
        <td>{{ product.original_name ? product.original_name : product.name   }}</td>
        <td>{{ $numberFormat(product.price) }}</td>
        <td>{{ product.amount }}</td>
        <td>{{ $numberFormat(product.price * product.amount) }}</td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td>{{ $localize('product')['total'] }}</td>
        <td></td>
        <td></td>
        <td>{{ $numberFormat(remainder) }}</td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'ProductsTable',
  props: {
    products: VueTypes.arrayOf(VueTypes.object).isRequired,
    remainder: VueTypes.number.isRequired,
  },
};
</script>

<style lang="scss" scoped>
  $border-color: rgba(0, 0, 0, 0.08);
  $font-size: 14px;

  .table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid transparent;
    border-bottom-color: $border-color;
    font-size: $font-size;

    @media (max-width: $mobile) {
      font-size: $font-size - 4px;
    }

    thead, tfoot {
      color: $main;
      font-weight: bold;
    }

    thead tr th {
      font-size: $font-size + 2px;
      color: $main;
      font-weight: bold;

      @media (max-width: $mobile) {
        font-size: $font-size - 4px;
      }

    }

    td, th {
      border: 1px solid $border-color;
      border-bottom-color: transparent;
      border-right-color: transparent;
      text-align: center;
      padding: 10px;

      &:first-child {
        text-align: left;
        border-left-color: transparent;
      }

      &:last-child {
        text-align: center;
        border-right-color: transparent;
      }
    }
  }
</style>